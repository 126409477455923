import React, { useEffect, useState } from "react";
import "./CompanyList.scss";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "./customeStyles";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import { CompanyDTO } from "../../../api/auth/apiClient";
import { ReactComponent as EditCompany } from "./svg/edit.svg";
import { ReactComponent as DeleteCompany } from "./svg/trashCan.svg";
import { ReactComponent as Add } from "./svg/plus-large.svg";
import { ReactComponent as ReturnToken } from "./svg/cancel.svg";
import { ReactComponent as UpdateToken } from "./svg/update.svg";
import { ReactComponent as InfoPorts } from "./svg/info.svg";
import CreateEditCompany from "./CreateEditCompany/CreateEditCompany";
import { NotificationType } from "../../Notification/notificationTypes";
import Notification from "../../Notification/Notification";
import { messages } from "../../../i18n/messages";
import ModalNotification from "../UpdatePage/Uploader/Modal/ModalNotification";
import UpdateCompanyToken from "./UpdateCompanyTokenModal/UpdateCompanyToken";
import { Hint } from "@skbkontur/react-ui";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";
import Ports from "./InfoAboutPorts/Ports";

type CompanyListProps = {
  companies: Array<CompanyDTO>;
  currentCompany: CompanyDTO;
  getCompanies: () => void;
  setCurrentCompany: (company: CompanyDTO) => void;
};

function CompanyList(props: CompanyListProps) {
  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [showModalRevokeToken, setShowModalRevokeToken] = useState(false);
  const [showUpdateToken, setShowUpdateToken] = useState(false);
  const [showInfoPorts, setShowInfoPorts] = useState(false);
  const [showDeleteCompany, setShowDeleteCompany] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    props.getCompanies();
  }, []);

  async function deleteCompany(companyId: string) {
    setShowDeleteCompany(false);
    try {
      await mapClient.deleteCompany(companyId);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.delete_company_success,
        }),
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.delete_company_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getCompanies();
  }

  async function revokeCompanyToken(companyId: string) {
    setShowModalRevokeToken(false);
    try {
      await mapClient.revokeCompanyToken(companyId);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.revoke_company_token_success,
        }),
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.revoke_company_token_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getCompanies();
  }

  function onShowModalDeleteCompany(company: CompanyDTO) {
    props.setCurrentCompany(company);
    setShowModalRevokeToken(true);
  }

  const columns: TableColumn<CompanyDTO>[] = [
    {
      name: messages.table_company_name,
      selector: (company) => company.name,
      sortable: true,
    },
    {
      name: messages.table_company_token,
      selector: (company) => company.token ?? "-",
      sortable: true,
      wrap: true,
      center: true,
      compact: true,
      grow: 2,
    },

    {
      name: "",
      cell: (company) => (
        <>
          <Hint text={messages.table_company_revoke_token}>
            <ReturnToken
              className="mr-3"
              onClick={() => onShowModalDeleteCompany(company)}
            />
          </Hint>
          <Hint text={messages.table_company_update_token}>
            <UpdateToken
              onClick={() => {
                props.setCurrentCompany(company);
                setShowUpdateToken(true);
              }}
            />
          </Hint>
        </>
      ),
      center: true,
    },

    {
      name: messages.table_company_server_address,
      selector: (company) => company.host,
      wrap: true,
      center: true,
    },
    {
      name: "",
      cell: (company) => (
        <>
          <Hint text={messages.information_ports}>
            <InfoPorts
              onClick={() => {
                props.setCurrentCompany(company);
                setShowInfoPorts(true);
              }}
            />
          </Hint>
        </>
      ),
      center: true,
    },

    {
      name: "",
      cell: (company) => (
        <>
          <EditCompany
            className="mr-3"
            onClick={() => {
              props.setCurrentCompany(company);
              setShowCreateCompany(true);
            }}
          />
          <DeleteCompany
            onClick={() => {
              props.setCurrentCompany(company);
              setShowDeleteCompany(true);
            }}
          />
        </>
      ),
      center: true,
    },
  ];

  return (
    <>
      {showCreateCompany ? (
        <CreateEditCompany
          getCompanies={props.getCompanies}
          setShowCreateCompany={setShowCreateCompany}
          currentCompany={props.currentCompany}
        />
      ) : null}
      {showDeleteCompany ? (
        <div>
          <ModalNotification
            modalQuestion={messages.delete_company_question}
            onClose={() => setShowDeleteCompany(false)}
            onAcceptCloseForm={() => deleteCompany(props.currentCompany.id!)}
          />
        </div>
      ) : null}

      {showModalRevokeToken ? (
        <div>
          <ModalNotification
            modalQuestion={messages.revoke_token_question}
            onClose={() => setShowModalRevokeToken(false)}
            onAcceptCloseForm={() =>
              revokeCompanyToken(props.currentCompany.id!)
            }
          />
        </div>
      ) : null}

      {showUpdateToken ? (
        <UpdateCompanyToken
          currentCompany={props.currentCompany}
          getCompanies={props.getCompanies}
          setShowUpdateToken={setShowUpdateToken}
        />
      ) : null}
      {showInfoPorts ? (
        <div>
          <Ports
            currentCompany={props.currentCompany}
            setShowInfoPorts={setShowInfoPorts}
          />
        </div>
      ) : null}

      <div className="companyList">
        <div className="companyContainer">
          <div className="companyHeader">
            <h2 className="companyTitle">{messages.company_list_title}</h2>
            <button
              onClick={() => {
                props.setCurrentCompany({} as CompanyDTO);
                setShowCreateCompany(true);
              }}
              className="btn"
            >
              <Add className="mr-1" />
              <span>{messages.add}</span>
            </button>
          </div>
          <div className="company_body">
            <div className="company_table">
              <DataTable
                columns={columns}
                data={props.companies}
                noDataComponent={messages.table_empty}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                striped
                highlightOnHover
                pointerOnHover
                persistTableHead
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
        <Notification />
      </div>
    </>
  );
}

export default CompanyList;
